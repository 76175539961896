var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseLayout", [
    _c("div", { attrs: { id: "content" } }, [
      _c("div", { staticClass: "container" }, [
        _c("header", { staticClass: "mb-5" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md mb-4 mb-md-5" }, [
              _c("h1", { staticClass: "theme-text" }, [
                _vm._v("Engineer Performance")
              ])
            ])
          ]),
          _c("section", { staticClass: "stats" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "wrapper" }, [
                  _c("span", [_vm._v(_vm._s(_vm.totalSearch))]),
                  _c("p", [_vm._v("Product Searches (weekly)")])
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "wrapper" }, [
                  _c("span", [_vm._v(_vm._s(_vm.totalCompare))]),
                  _c("p", [_vm._v("Products Compared (weekly)")])
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "wrapper" }, [
                  _c("span", [
                    _vm._v("£ " + _vm._s(_vm.totalWorthOfProductEmailed))
                  ]),
                  _c("p", [_vm._v("Worth of products emailed")])
                ])
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "wrapper" }, [
                  _c("span", [_vm._v(_vm._s(_vm.totalEmailed))]),
                  _c("p", [_vm._v("Emailed (weekly)")])
                ])
              ]),
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "div",
                  { staticClass: "wrapper" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-between mb-3"
                      },
                      [
                        _c("h2", [_vm._v("Statistics")]),
                        _c("form", { attrs: { action: "" } }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.monthFilterValue,
                                  expression: "monthFilterValue"
                                }
                              ],
                              attrs: { name: "MonthFilterValue" },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.monthFilterValue = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function($event) {
                                    return _vm.onChangeFilter()
                                  }
                                ]
                              }
                            },
                            [
                              _c("option", { attrs: { value: "3" } }, [
                                _vm._v("Last 3 months")
                              ]),
                              _c("option", { attrs: { value: "6" } }, [
                                _vm._v("Last 6 months")
                              ]),
                              _c("option", { attrs: { value: "12" } }, [
                                _vm._v("Last 12 months")
                              ])
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm.loaded
                      ? _c("bar-chart", {
                          attrs: {
                            chartdata: _vm.chartdata,
                            options: _vm.options
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }