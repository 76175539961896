<template>
  <BaseLayout>
    <div id="content">
      <div class="container">
        <header class="mb-5">
          <div class="row">
            <div class="col-md mb-4 mb-md-5">
              <h1 class="theme-text">Engineer Performance</h1>
            </div>
          </div>

          <section class="stats">
            <div class="row">
              <div class="col-md-3">
                <div class="wrapper">
                  <span>{{ totalSearch }}</span>
                  <p>Product Searches (weekly)</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="wrapper">
                  <span>{{ totalCompare }}</span>
                  <p>Products Compared (weekly)</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="wrapper">
                  <span>&pound; {{ totalWorthOfProductEmailed }}</span>
                  <p>Worth of products emailed</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="wrapper">
                  <span>{{ totalEmailed }}</span>
                  <p>Emailed (weekly)</p>
                </div>
              </div>

              <div class="col-md-12">
                <div class="wrapper">
                 <div class="d-flex align-items-center justify-content-between mb-3"><h2>Statistics</h2>
                  <form action="">
                    <select name="MonthFilterValue" v-model="monthFilterValue" @change="onChangeFilter()">
                      <option value="3">Last 3 months</option>
                      <option value="6">Last 6 months</option>
                      <option value="12">Last 12 months</option>
                    </select>
                  </form>
                  </div>
<!-- <line-chart></line-chart> -->
<bar-chart
      v-if="loaded"
      :chartdata="chartdata"
      :options="options"/>
                  <!-- <img
                    src="./../../assets/images/stats.png"
                    class="w-100"
                    alt="stats"
                  /> -->
                </div>
              </div>
            </div>
          </section>
        </header>
      </div>
    </div>
  </BaseLayout>
</template>
<script>
import BaseLayout from "@/layouts/base";
import BarChart from '@/components/BarChart'
import StatisticService from "@/services/statistic.service";

export default {
  name: "EngineerPerformance",
  components: {
    BaseLayout,
    BarChart
  },
  data: function () {
    return {
      totalSearch: 0,
      totalCompare: 0,
      totalWorthOfProductEmailed: 0,
      totalEmailed: 0,
      loaded: false,
      chartdata: null,
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
      monthsLabel: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      totalSearchMonthly:[],
      totalProductEmailedMonthly:[],
      totalCompareMonthly:[],
      totalEmailMonthly:[],
      monthFilterValue: 3,
    };
  },
  mounted() {
    this.getWeeklyTotalSearch();
    this.getWeeklyTotalCompare();
    this.getWeeklyTotalEmailProductWorth();
    this.getWeeklyTotalEmail();
    this.getMonthlyChartData(this.monthFilterValue);
  },
  methods: {
    getWeeklyTotalSearch: function () {
      StatisticService.getTotalSearch(this.getCurrentTime(), this.getPreviousWeekTime(),'weekly').then((response) => {
        this.totalSearch = response.total_search;
      });
    },
    getWeeklyTotalCompare: function () {
      StatisticService.getTotalCompare(this.getCurrentTime(), this.getPreviousWeekTime(),'weekly').then((response) => {
        this.totalCompare = response.total_compare;
      });
    },
    getWeeklyTotalEmailProductWorth: function () {
      StatisticService.getTotalEmailProductWorth(this.getCurrentTime(), this.getPreviousWeekTime()).then((response) => {
        this.totalWorthOfProductEmailed = response.total_price;
      });
    },
    getWeeklyTotalEmail: function () {
      StatisticService.getTotalEmailed(this.getCurrentTime(), this.getPreviousWeekTime(),'weekly').then((response) => {
        this.totalEmailed = response.total_emailed;
      });
    },
    getCurrentTime: function () {
      return new Date();
    },
    getPreviousWeekTime: function() {
      var previousWeekTime = new Date();
      previousWeekTime.setDate(previousWeekTime.getDate() - 7);
      return previousWeekTime
    },
    getPreviousmonths: function(numberOfMonths) {
      var previousMonthsTime = new Date();
      var diffInMonths = numberOfMonths - 1
      previousMonthsTime.setMonth(previousMonthsTime.getMonth() - diffInMonths);
      previousMonthsTime.setDate(1);
      return previousMonthsTime
    },
    getMonthlyTotalSearch: function (numberOfMonths) {
      StatisticService.getTotalSearch(this.getCurrentTime(), this.getPreviousmonths(numberOfMonths),'monthly').then((response) => {
        this.totalSearchMonthly = response.total_search;
        
        if(this.totalSearchMonthly){         
          this.data1 = this.manipulateMonthData(this.totalSearchMonthly, numberOfMonths);
          console.log(this.data1);
        }
        
      });
    },
    getMonthlyTotalCompare: function (numberOfMonths) {
      StatisticService.getTotalCompare(this.getCurrentTime(), this.getPreviousmonths(numberOfMonths),'monthly').then((response) => {
        this.totalCompareMonthly = response.total_compare;
        
        if(this.totalCompareMonthly){         
          this.data2 = this.manipulateMonthData(this.totalCompareMonthly, numberOfMonths);
          console.log(this.data2);
        }
      });
    },

     getMonthlyTotalProductEmailedMonthly: function (numberOfMonths) {
      StatisticService.getTotalProductEmailed(this.getCurrentTime(), this.getPreviousmonths(numberOfMonths),'monthly').then((response) => {
        this.totalProductEmailedMonthly = response.total_product_emailed;
        
        if(this.totalProductEmailedMonthly){         
          this.data3 = this.manipulateMonthData(this.totalProductEmailedMonthly, numberOfMonths);
          console.log(this.data2);
        }
      });
    },

    getMonthlyTotalEmailed: function (numberOfMonths) {
      StatisticService.getTotalEmailed(this.getCurrentTime(), this.getPreviousmonths(numberOfMonths),'monthly').then((response) => {
        this.totalEmailMonthly = response.total_emailed;
        
        if(this.totalEmailMonthly){         
          this.data4 = this.manipulateMonthData(this.totalEmailMonthly, numberOfMonths);
          this.data3 = this.manipulateMonthData(this.totalProductEmailedMonthly, numberOfMonths);
          this.data2 = this.manipulateMonthData(this.totalCompareMonthly, numberOfMonths);
          this.data1 = this.manipulateMonthData(this.totalSearchMonthly, numberOfMonths);
          console.log(this.data2);
        }
        setTimeout(() => this.generateChart() , 1000);
      });
    },
    generateChart: function (){
        this.loaded = true;
        this.chartdata = {
         labels: this.monthsLabel,
         datasets: [{
            label: 'Number of product Search',
            data: this.data1,
            backgroundColor: 'green',
            borderColor: 'rgb(201, 203, 207)',
            borderWidth: 1
          },
          {
            label: 'Number of Product Compared',
            data: this.data2,
            backgroundColor: 'blue',
            borderColor: 'rgb(201, 203, 207)',
            borderWidth: 1
          },
          {
            label: 'Number of Product emailed',
            data: this.data3,
            backgroundColor: 'red ',
            borderColor: 'rgb(201, 203, 207)',
            borderWidth: 1
          },
          {
            label: 'Number of emailed',
            data: this.data4,
            backgroundColor: 'yellow ',
            borderColor: 'rgb(201, 203, 207)',
            borderWidth: 1
          }
          
          ]
        };
    },
    getMonthlyChartData:function (numberOfMonths){
      this.monthsLabel = this.getMonthLabels(numberOfMonths);
      console.log(this.monthsLabel);
      this.getMonthlyTotalSearch(numberOfMonths);
      this.getMonthlyTotalCompare(numberOfMonths);
      this.getMonthlyTotalProductEmailedMonthly(numberOfMonths);
      this.getMonthlyTotalEmailed(numberOfMonths);

    },
    getMonthLabels:function (numberOfMonths){
        var monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

        var today = new Date();
        var lastMonths = []

        for (var i = 0; i < numberOfMonths; i++) {
          console.log((today.getMonth() - i));
          var count = today.getMonth() - i;
          if(count < 0){
            count = 12 + count
          }
          lastMonths.push(monthNames[count]);
        }
        return lastMonths.reverse();
    },
    manipulateMonthData:function(datasets, numberOfMonths){
         var data = [];
          data.push(0);
          var today = new Date();
          for (var i = 0; i < numberOfMonths; i++) {
            datasets.forEach(async function(dataset) {
                if(dataset['month'] == (today.getMonth() + 1 - i)){
                  data[i+1] = dataset['c'];
                } else{
                  console.log(dataset['month']);
                  if(data[i+1]){
                    data[i+1] = data[i+1];
                  } else{
                  data[i+1] = 0;
                  }
                }
            });
          }
          return data.reverse()
    },
    onChangeFilter:function(){
        this.loaded = false;
        this.chartdata = null;
        this.getMonthlyChartData(this.monthFilterValue);
    }

  },
};
</script>
<style scoped>
</style>